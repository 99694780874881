import { httpGet, httpPost, httpPut, toQueryString } from '../Service';

// 조회
export function getTxCnfmInfo(delAskNo) {
	return httpGet('/api/v1.0/erp/txCnfmSms/' + delAskNo);
}

// 등록 카운트 조회
export function getTxCnfmInfoCnt(delAskNo) {
	return httpGet('/api/v1.0/erp/txCnfmSms/' + delAskNo + '/cnt');
}

// 인수인정보/서명 저장
export function postTxCnfmInfo(data) {
	return httpPost('/api/v1.0/erp/txCnfmSms/' + data.P_DEL_ASK_NO, { body: data });
}
