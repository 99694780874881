import React, { Component } from 'react';
import Header from '../include/Header';
import LeftMenu from '../include/LeftMenu';
import IBSheet from '../IBSheet';

import Tooltip from '@material-ui/core/Tooltip';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import TextField from '@material-ui/core/TextField';

import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import DetailIcon from '@material-ui/icons/Assignment';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';

import Menu from '@material-ui/core/Menu';

import { dateToFormat, errorMsgCheck, resultToIbSheet, excelDown, getCustomCheck, SheetParamData5 } from '../../common/ComFunction';

import {
	getSalesAssetSearchList,
	putSalesAssetRequst
} from '../../service/admin/SalesAssetSearchService';
import { getCisCommCode, getCustomData } from '../../service/CommonService';
import ExcelDown from '../include/ExcelDown';

import { observer } from 'mobx-react';
import CommonStore from '../../common/CommonStore';
const { ModalStore, ConfirmModalStore, ProgressStore, CustSearchPopupStore, UserPopupStore, RentalOrderPopStore } = CommonStore;

import { CUST_NO_AJ, CUST_NO_DDI } from '../../common/ComValues';

class SalesAssetSearch extends Component {
	constructor(props) {
		super(props);

		this.state = {
			startDate: moment(),
			endDate: moment(),
			sCustNo: sessionStorage.custNo,
			sCustNm: sessionStorage.custNm,
			sUserNm: sessionStorage.userName,
			sUserId: sessionStorage.userId,
			sOrdeNo: '',
			sSellStatus: '',
			//excel
			excelList: [], //엑셀다운로드용 리스트
			disabledBtn: false, // 매각처리완료 버튼 여부 체크
		};

		this.sheetData = {
			id: 'salesAssetSearchSheet', // sheet id
			initData: 'admin/Init-SalesAssetSearchList', // sheet initData javascript filename
			//data: "data", // sheet data javascript filename
			width: '100%', // sheet width
			height: '587px', // sheet height
		};
	}

	componentDidMount = () => {
		// aj인경우 공백으로 넣기
		getCustomData({ custNo: sessionStorage.custNo, customCode: 'CT012' }).then((r) => {
			if (r.length > 0) {
				this.setState({
					sCustNo: '',
					sCustNm: '',
					sUserNm: '',
					sUserId: '',
				});
			}
		});

		getCisCommCode('07').then((r) => {
			this.setState({
				delList: r,
			});
		});
	};

	componentWillUnmount = () => {
		if (window[this.sheetData.id]) {
			window[this.sheetData.id].DisposeSheet();
		}
	};

	dateHandler = (date, type) => {
		if (type === 's') {
			this.setState({ startDate: date });
		} else {
			this.setState({ endDate: date });
		}
	};

	searchCustomer = () => {
		//관리자만 조회할 수 있다.
		//if(sessionStorage.authCode <= 1){
		CustSearchPopupStore.openPopup('1', null, null, this.handleCustSearch);
		//}
	};

	handleCustSearch = (item) => {
		if (item !== undefined) {
			this.setState({
				sCustNo: item.custNo,
				sCustNm: item.custNm,
			});
		}
	};
	
	getSearchParam = () => {
		// 대표고객이면서 고객관리자인 경우 현재 대표고객으로 조회시 하위 고객사까지 조회 가능하다
		var repYn = '';
		if (sessionStorage.custNo === sessionStorage.repCustNo && sessionStorage.custNo === this.state.sCustNo && sessionStorage.authCode === '2') {
			repYn = 'Y';
		} else {
			repYn = 'N';
		}
		
		let param = {
			custNo: this.state.sCustNo,
			userId: this.state.sUserId,
			sellStatus: this.state.sSellStatus,
			startDate: dateToFormat(this.state.startDate),
			endDate: dateToFormat(this.state.endDate),
			ordeNo: this.state.sOrdeNo,
			repYn: repYn, // 대표고객이 하위 고객사 전체 조회 가능함 20190716 kkj
		};
		return param;
	};

	searchList = () => {
		let param = this.getSearchParam();

		ProgressStore.activeProgress();
		getSalesAssetSearchList(param)
			.then((r) => {
				window[this.sheetData.id].LoadSearchData(resultToIbSheet(r));
				this.setState({
					custNo: '', //고객번호
					ordeNo: '',
					appList: [],
					delStatus: '',
					activeStep: '',
				});
			})
			.catch((err) => {
				errorMsgCheck(err);
			})
			.finally(() => {
				ProgressStore.deactiveProgress();
			});
	};

	searchExcelList = () => {
		let param = this.getSearchParam();

		ProgressStore.activeProgress();

		getSalesAssetSearchList(param)
			.then((r) => {
				// console.log(r);
				if (r.length === 0) {
					ModalStore.openModal('검색된 내용이 없습니다.');
				} else {
					excelDown(
						this.makeExcelData(r),
						(this.state.sCustNm
							? `${this.state.sCustNm}(${dateToFormat(this.state.startDate)}~${dateToFormat(this.state.endDate)})`
							: `(${dateToFormat(this.state.startDate)}~${dateToFormat(this.state.endDate)})`) + '_교원매각처리리스트.csv',
					);
				}
			})
			.catch((err) => {
				errorMsgCheck(err);
			})
			.finally(() => {
				ProgressStore.deactiveProgress();
			});
	};

	makeExcelData = (data) => {
		let tmpList = [];
		let tmp = {};
		data.map((item, idx) => {
			tmp = {};
			tmp.No = idx + 1;
			tmp.고객요청번호 = item.custReqNo;
			tmp.발주번호 = item.ordeNo;
			tmp.주문번호 = item.contNo;
			tmp.자산번호 = item.asstNo;
			tmp.처리여부 = item.sellStatusNm;
			tmp.등록일 = dateToFormat(item.instDate);
			tmp.처리일 = dateToFormat(item.updtDate);
			tmpList.push(tmp);
		});
		// this.setState({ excelList: tmpList });
		return tmpList;
	};

	requestSales = (statusCd) => {
		let sheet = window[this.sheetData.id];
		let FindCheckedRow = sheet.FindCheckedRow('chk');
		let checkRows = FindCheckedRow.split('|');
		// 처리버튼(완료/취소) 누르자마자 안보이게 처리
		this.setState({
			disabledBtn: true,
		});
		if (!FindCheckedRow) {
			ModalStore.openModal('선택된 건이 없습니다.');
			this.setState({
				disabledBtn: false,
			});
			return;
		} else if (checkRows.length > 10){
			ModalStore.openModal('10건 이하로 체크해 주세요.');
			this.setState({
				disabledBtn: false,
			});
			return;
		} else {
			let valid = true;
			if (statusCd == '01'){ // 완료 처리 validation
				checkRows.forEach(row => {
					let value = sheet.GetCellValue(row, 'sellStatus');
					if(value == '01' || value == '02' ) {
						ModalStore.openModal('처리 상태가 미처리인 건만 체크해 주세요.');
						this.setState({
							disabledBtn: false,
						});
						valid = false;
					}
				});
				
			}  else if (statusCd == '02'){ // 취소 처리 validation
				checkRows.forEach(row => {
					let value = sheet.GetCellValue(row, 'sellStatus');
					if(value == '02') {
						ModalStore.openModal('처리 상태가 미처리/완료인 건만 체크해 주세요.');
						this.setState({
							disabledBtn: false,
						});
						valid = false;
					}
				});
			}
			if(valid) {
				const sheetParam = SheetParamData5(sheet, null);
				console.log(sheetParam);
				if (sheetParam && sheetParam.data.length > 0) {
					putSalesAssetRequst(sheetParam, statusCd)
						.then((r) => {
							console.log(r);
							ModalStore.openModal(statusCd == '01'? '매각 처리를 완료했습니다.' : '매각 취소를 완료했습니다.');
							this.setState({
								disabledBtn: false,
							});
						})
						.catch((err) => {
							errorMsgCheck(err, ProgressStore.deactiveProgress());
						})
						.finally(() => {
							ProgressStore.deactiveProgress();
							this.searchList();
						});
				}
			}
		}
	}

	render() {
		const dateInput = <input type="text" style={{ width: 120 }} />;

		let appInfoStyle = {
			textAlign: 'center',
			border: 0,
		};

		const detailInputStyle = {
			border: 0,
		};

		return (
			<div>
				<Header />
				<div className="container" id="container">
					<div className="inner">
						<div className="leftMenu">
							<LeftMenu />
						</div>
						<div className="rightCont" style={{ height: 1200 }}>
							<div className="h2Wrap mb30">
								<h2>교원매각처리조회</h2>
								<div className="btnRight">
									<a className="btn01 search" onClick={this.searchList}>
										<span>조회</span>
									</a>
									<a className="btn03 save" onClick={() => this.searchExcelList()}>
										<span>엑셀</span>
									</a>
								</div>
							</div>

							<div className="writeType03">
								<table>
									<colgroup>
										<col style={{ width: '90px' }} />
										<col style={{ width: '280px' }} />
										<col style={{ width: '90px' }} />
										<col style={{ width: '140px' }} />
										<col style={{ width: '90px' }} />
										<col style={{ width: '120px' }} />
									</colgroup>
									<tbody>
										<tr>
											<th>고객명</th>
											<td colSpan="5">
												<input type="text" readOnly={true} style={{ marginRight: 10, width: 235 }} value={this.state.sCustNm} />
												<IconButton onClick={this.searchCustomer} color="secondary" className="iconButton" style={{ display: sessionStorage.btnControl }}>
													<SearchIcon fontSize="small" />
												</IconButton>
											</td>
										</tr>
										<tr>
											<th>신청일자</th>
											<td>
												<DatePicker
													customInput={dateInput}
													dateFormat="YYYY-MM-DD"
													selectsStart
													selected={this.state.startDate}
													onChange={(date) => this.dateHandler(date, 's')}
													startDate={this.state.startDate}
													endDate={this.state.endDate}
													isClearable={true}
													showMonthDropdown
													showYearDropdown
													dropdownMode="select"
												/>
												<span style={{ marginLeft: 10, marginRight: 10 }}>~</span>
												<DatePicker
													customInput={dateInput}
													dateFormat="YYYY-MM-DD"
													selectsEnd
													selected={this.state.endDate}
													onChange={(date) => this.dateHandler(date, 'e')}
													startDate={this.state.startDate}
													endDate={this.state.endDate}
													isClearable={true}
													showMonthDropdown
													showYearDropdown
													dropdownMode="select"
												/>
											</td>
											<th>요청번호</th>
											<td>
												<input type="text" style={{ width: 130 }} value={this.state.sOrdeNo} onChange={(e) => this.setState({ sOrdeNo: e.target.value })} />
											</td>
											<th>진행상태</th>
											<td>
												{/* 00 : 미처리 , 01 : 완료 , 02 : 취소 */}
												<select defaultValue={''} onChange={(e) => this.setState({ sSellStatus: e.target.value })}>
													<option value="">전체</option>
													<option value="00">미처리</option>
													<option value="01">완료</option>
													<option value="02">취소</option>
												</select>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
							<div style={{ marginTop: 15, textAlign: 'right' }}>
								
								<Button
									color="secondary"
									variant="contained"
									size="small"
									style={{ alignItems: 'initial', marginRight: 5, backgroundColor: '#0062cc' }}
									onClick={() => this.requestSales('01')}
									disabled={this.state.disabledBtn}
								>
									매각완료
								</Button>

								<Button
										color="secondary"
										variant="outlined"
										size="small"
										style={{ marginLeft: 10, alignItems: 'initial' }}
										onClick={() => this.requestSales('02')}
										disabled={this.state.disabledBtn}
									>
										매각취소
									</Button>
								
							</div>
							<div className="writeType03">
								<div
									className="scroll"
									onMouseOver={() => {
										document.body.style.overflow = 'hidden';
									}}
									onMouseOut={() => {
										document.body.style.overflow = 'auto';
									}}
								>
									<p className="headline">리스트</p>
									<IBSheet {...this.sheetData} />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
export default observer(SalesAssetSearch);
